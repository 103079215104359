<template>

<div class="grid p-fluid">
    <div class="col-12 md:col-12">
        <div class="card card-w-title">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
            <div class="grid">
                <loading
                    :active="isLoadingModel"
                    :can-cancel="true"
                    color="#173EA3"
                    :is-full-page="fullPage"
                    ></loading>
                <div class="col-12">
                    <div class="card">                        
                        <div class="p-fluid formgrid grid">
                        
                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">
                                    <Dropdown id="status" v-model="status_dropdownItem" :options="status_dropdownItems" 
                                        optionLabel="name" filter
                                        :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                                    </Dropdown>
                                    <label for="status">Status</label>
                                </span>
                            </div>                                           

                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <InputText id="firstname2" type="text" v-model="product.first_name"
                                        :class="{ 'p-invalid': submitted && !product.first_name }" />
                                    <label for="firstname2">Firstname</label>
                                </span>
                            </div>
                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <InputText id="lastname2" type="text" v-model="product.last_name"
                                        :class="{ 'p-invalid': submitted && !product.last_name }" />
                                    <label for="lastname2">Lastname</label>
                                </span>
                            </div>


                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <InputText id="password" type="password" v-model="product.password"
                                        :class="{ 'p-invalid': submitted && !product.password }" />
                                    <label for="password">Password</label>
                                </span>
                            </div>
                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">
                                <Dropdown id="user_type" v-model="user_type_dropdownItem" :options="user_type_dropdownItems"
                                    optionLabel="name" filter
                                    :class="{ 'p-invalid': submitted && !user_type_dropdownItem }"></Dropdown>
                            
                                <label for="user_type">User Type</label>
                                </span>
                            </div>      

                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <InputText id="email" type="text" v-model="product.email_id"
                                        :class="{ 'p-invalid': submitted && !product.email_id }" />
                                    <label for="email">Email</label>
                                </span>
                            </div>
                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <InputText type="number" id="mobile_number" v-model="product.mobile_number"
                                        :class="{ 'p-invalid': submitted && !product.mobile_number }" />
                                    <label for="mobile_number">Mobile Number</label>
                                </span>
                            </div>

                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">
                            <Dropdown id="gender" v-model="gender_dropdownItem" :options="gender_dropdownItems"
                                    optionLabel="name" filter
                                    :class="{ 'p-invalid': submitted && !gender_dropdownItem }"></Dropdown>
                                    <label for="gender">Gender</label>
                                    </span>
                            </div>
                            <div class="field col-12 md:col-6" style="margin-top: 1%;">
                                <span class="p-float-label">
                                <Calendar
                            
                                v-model="product.dob"
                                dateFormat="dd-mm-yy"
                                :showIcon="true"
                                :showTime="false"
                                hourFormat="12"
                                />
                                <label for="dob">Birth Date</label>
                            </span>
                            </div>

                            <div class="field col-12" style="margin-top: 1%;">
                                <span class="p-float-label">

                                    <Textarea id="address" rows="4" v-model="product.address"
                                        :class="{ 'p-invalid': submitted && !product.address }" />
                                    <label for="address">Address</label>
                                </span>
                            </div>

                    
                            <div class="field col-12 md:col-2" style="margin-top: 1%;">
                                <Button v-if="product._id" label="Update" class="p-button-success mr-2" @click="Add_user" />
                                <Button v-else label="Add" class="p-button-success mr-2" @click="Add_user" />
                            </div>
                            <div class="field col-12 md:col-2" style="margin-top: 1%;">
                                <Button label="Reset" class="p-button-danger mr-2" @click="reset_user" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>



                       
</template>

<script>
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
// import BulkUploadUser from '../components/menu/BulkUploadUser'
import { FilterMatchMode } from "primevue/api";
import InputValidation from '../service/InputValidation';
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
export default {
    data() {
        return {
            
            active:"active",
            tabs : ['Add User'],
            selectedTab: "Add User",


            departments:[],
            department:null,
            location_dropdown: [],
            location_search:'',
            place_details:'',
            client_id:'',
            user_type:'',
            id: "",
            list: [],
           
            status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                { name: "Deleted", value: "Deleted" },
            ],
            status_dropdownItem: null,

            gender_dropdownItems: [
                { name: 'Male', value: 'Male' },
                { name: 'Female', value: 'Female' },
                { name: 'Other', value: 'Other' }
            ],
            gender_dropdownItem: null,


            state_dropdownItems: [{ name: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { name: 'Assam', value: 'Assam' }, { name: 'Bihar', value: 'Bihar' }, { name: 'Chhattisgarh', value: 'Chhattisgarh' }, { name: 'Goa', value: 'Goa' }, { name: 'Gujarat', value: 'Gujarat' }, { name: 'Haryana', value: 'Haryana' }, { name: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { name: 'Jharkhand', value: 'Jharkhand' }, { name: 'Karnataka', value: 'Karnataka' }, { name: 'Kerala', value: 'Kerala' }, { name: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { name: 'Maharashtra', value: 'Maharashtra' }, { name: 'Manipur', value: 'Manipur' }, { name: 'Meghalaya', value: 'Meghalaya' }, { name: 'Mizoram', value: 'Mizoram' }, { name: 'Nagaland', value: 'Nagaland' }, { name: 'Odisha', value: 'Odisha' }, { name: 'Punjab', value: 'Punjab' }, { name: 'Rajasthan', value: 'Rajasthan' }, { name: 'Sikkim', value: 'Sikkim' }, { name: 'Tamil Nadu', value: 'Tamil Nadu' }, { name: 'Telangana', value: 'Telangana' }, { name: 'Tripura', value: 'Tripura' }, { name: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { name: 'Uttarakhand', value: 'Uttarakhand' }, { name: 'West Bengal', value: 'West Bengal' }],
            state_dropdownItem: { name: "", value: "" },

            user_type_dropdownItems: [],
            user_type_dropdownItem: null,

            client_dropdownItems: [],
            client_dropdownItem: null,

            show_name:"Add",

            product: {},
            submitted: false,

            BreadcrumbService : null,
            breadcrumbHome: {},
            breadcrumbItems: [],
        }

    },
    productService: null,
    methods: {
   
        Add_user() {
            this.submitted = true;
            this.product.location=this.place_details;
           
            if (this.status_dropdownItem==null || this.status_dropdownItem.value=='' || this.status_dropdownItem.value==undefined ||  this.status_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select Status",
                    life: 3000,
                });
                return false;
            }
            if (this.user_type_dropdownItem==null || this.user_type_dropdownItem.value=='' || this.user_type_dropdownItem.value==undefined ||  this.user_type_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select User Type",
                    life: 3000,
                });
                return false;
            }
       

            if (!this.product.first_name) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter First Name",
                    life: 3000,
                });
                return false;
            }

            if (!this.product.last_name) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Last Name",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.password) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Password",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.email_id) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Email ID",
                    life: 3000,
                });
                return false;
            }
            var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if(this.product.email_id)
            {
                if(emailreg.test(this.product.email_id)==false)
                {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Email ID",
                    life: 3000,
                });
                  return false;
                }
            }
            const phoneRegex = /^[0-9]{10}$/;
            if (!this.product.mobile_number || (this.product.mobile_number && !phoneRegex.test(this.product.mobile_number))) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Mobile No",
                    life: 3000,
                });
                return false;
            }
        
            if (this.gender_dropdownItem==null || this.gender_dropdownItem.value=='' || this.gender_dropdownItem.value==undefined ||  this.gender_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select Gender",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.dob) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Date of Birth",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.address) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Address",
                    life: 3000,
                });
                return false;
            }       
          
            if (!this.product.city) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter city",
                    life: 3000,
                });
                return false;
            }            
            var data = {                
                "first_name": this.product.first_name,
                "last_name": this.product.last_name,
                "email_id": this.product.email_id,
                "mobile_number": this.product.mobile_number,
                "gender": this.gender_dropdownItem.value,
                "password": this.product.password,
                "alternate_contact_no": this.product.alternate_contact_no,
                "user_type": this.user_type_dropdownItem.value,
                "status": this.status_dropdownItem.value,
                "created_at": "",
                "created_by": this.id,
                "dob":this.dateFormatYMD(this.product.dob),
                "address":this.product.address               
            };
            console.log(data);
            this.isLoadingModel = true;
            var promise1 = apis.regAdminUser(data);
            promise1
                .then((responseapi) => {
                    this.$swal(responseapi.data.message);
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"usermaster"});

                })
                .catch((error) => {
                    this.isLoadingModel = false;
                    this.$swal.fire(error.response.data);

                });
        },
        getdepartment_list: function () {
      
      var data = { client_id:localStorage.getItem("client_id")};
      this.loading = true;
      var promise = apis.clientdepartmentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.departments = response.data.data.map(depart => ({ name: depart.name, value: depart._id }));
        
      });

      console.log(data);
    },
        
        get_user_type(client_id) {
            
            
            this.loading = true;
            var data = { 
               // _id: this.id
                _id: client_id
            };
            var promise = apis.user_rolelist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({ name: response.data.data[x].user_role_name, value: response.data.data[x].user_role_name });

                    }
                    this.user_type_dropdownItems = temp_data;
                }

            });
        },
        reset_user() {
            this.product = {};
            this.submitted = false;
            this.status_dropdownItem = null;
            this.gender_dropdownItem = null;
            this.state_dropdownItem = null;
            this.user_type_dropdownItem = null;
            this.client_dropdownItem = null;
            this.user_dropdownItem = null;
            this.department=null;

            this.show_name="Add";

        },
        dateFormatYMD(inputDate) {
        var postdate=inputDate;
        if(inputDate)
        {
            inputDate=new Date(inputDate);
            if(inputDate.toString()=='NaN-NaN-NaN')
            {
                return postdate;                
            }
            else
            {
                let date = ("0" + inputDate.getDate()).slice(-2);
                let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
                let year = inputDate.getFullYear();
                if(date.toString()=='aN')
                {
                  return postdate;
                }
                else
                {
                  return year+"-"+month+"-"+date;
                }
            }
        }
        else {
           return "";
        }
    },
    },
    async created() {
        this.productService = new ProductService();
        this.InputValidation = new InputValidation();

        this.BreadcrumbService = new BreadcrumbService();
        let Breadcrum_data = this.BreadcrumbService.user_add()
        this.breadcrumbHome=Breadcrum_data.home;
        this.breadcrumbItems=Breadcrum_data.items;

	
        this.id = localStorage.getItem('id');
        this.client_id=localStorage.getItem('client_id');
        this.user_type=localStorage.getItem('user_key');
        //alert(this.user_type)
       if (localStorage.getItem('client_id')) 
       {
        await this.get_user_type(localStorage.getItem('client_id'));
       }
      
       await this.get_clientist();
       await this.getdepartment_list();
        if(localStorage.getItem('saveuserdata'))
      {
        this.product=await JSON.parse(localStorage.getItem('saveuserdata'));
     
        this.status_dropdownItem={};
        this.status_dropdownItem.value = await this.product.status;
        this.status_dropdownItem.name = await this.product.status;

        this.user_type_dropdownItem={};
        this.user_type_dropdownItem.value = await this.product.user_type;
        this.user_type_dropdownItem.name = await this.product.user_type;

        this.gender_dropdownItem={};
        this.gender_dropdownItem.value = await this.product.gender;
        this.gender_dropdownItem.name = await this.product.gender;

        this.state_dropdownItem={};
        this.state_dropdownItem.value = await this.product.state;
        this.state_dropdownItem.name = await this.product.state;
        this.product.pinvalue = await this.product.pincode;
        this.product.city = await this.product.city;

        if(this.product.location)
        {
            this.location_search={};
            this.location_search={ "name": this.product.location?this.product.location.Label:'', "value":this.product.location?this.product.location:''};
        }
        
        this.place_details=this.product.location?this.product.location:'';

        

        this.show_name="Update";
      }
      await localStorage.setItem('saveuserdata','');
      

    },

    components: { downloadExcel: JsonExcel, },
}
</script>
