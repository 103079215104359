export default class InputValidation {

    emailreg(data) 
    {   
        var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if(emailreg.test(data)==false)
        {  
            return false; 
        }
        else
        {
            return true;
        }
    }
    mobilereg(data) 
    {   
        const phoneRegex = /^[0-9]{10}$/;
        if(!phoneRegex.test(data))
        {  
            return false; 
        }
        else
        {
            return true;
        }
    }
    empty(data) 
    {   
        
        if(data=='' || data==null || data==undefined)
        {  
            return true; 
        }
        else
        {
            return false;
        }
    }
}